export enum IntegrationPaymentTypeEnum {
  paymentByAttachedCard = 'paymentByAttachedCard',
  paymentByPaymentLink = 'paymentByPaymentLink'
}

export const IntegrationPaymentType = {
  InternalStrategy: {
    // Оплата привязанной картой не будет использоваться (т.к. карту в админке никто привязывать не будет,
    // если только это пригодится в самообслуживании)
    paymentByAttachedCard: IntegrationPaymentTypeEnum.paymentByAttachedCard,
  },
  ExternalStrategy: {
    paymentByPaymentLink: IntegrationPaymentTypeEnum.paymentByPaymentLink
  },
}
