import { Injectable } from "@angular/core";
import { PaymentServiceModel } from "../models/payment-service.model";
import { PlaygroundModel } from "../models/playground.model";
import { PlaygroundService } from "./playground.service";
import { hasError } from "../utils/errors.utility";
import { PaymentSystemTypesEnum } from "../enums/payment-system-types.enum";
import { StripeService } from "./stripe.service";
import { SberService } from "./sber.service";

@Injectable({
  providedIn: 'root'
})

export class PaymentManagerService {

  public currentStrategy: string;

  private currentPlayground: PlaygroundModel;

  public currentService: PaymentServiceModel;

  constructor(
    private playgroundService: PlaygroundService,
    private stripeService: StripeService,
    private sberService: SberService
  ) {
  }

  getServiceByPlaygroundId(playgroundId: number) {
    this.playgroundService.getPlaygroundById(playgroundId)
      .subscribe(result => {
        if (hasError(result)) {
          return;
        }
        this.currentPlayground = result.data;
        switch (this.currentPlayground.paymentSystemType) {
          case PaymentSystemTypesEnum.Stripe: {
            this.currentService = this.stripeService;
            break;
          }
          case PaymentSystemTypesEnum.SberBank: {
            this.currentService = this.sberService;
            break;
          }
          default:
            throw new Error(`Unsupported payment service: ${this.currentPlayground.paymentSystemType}`);

        }
    this.currentStrategy = this.currentService.availableStrategy;
      })
  }
}
