import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {stripeEnvironment} from '../../../environments/stripe.environment';
import {loadStripe, Stripe, StripeElements} from '@stripe/stripe-js';
import {environment} from '../../../environments/environment';
import {AcceptPaymentDto, CheckoutSessionInfoDto} from '../models/stripe.model';
import {StorageService, StorageValue} from './storage.service';
import {ProxyService, Urls} from './proxy.service';
import {PaymentServiceModel} from "../models/payment-service.model";
import { IntegrationPaymentType } from "../enums/integration-payment-type.enum";

@Injectable({
  providedIn: 'root'
})
export class StripeService implements PaymentServiceModel {
  private _stripe: Stripe;
  readonly availableStrategy: string = IntegrationPaymentType.ExternalStrategy.paymentByPaymentLink;
  readonly authHeader = new HttpHeaders({ 'Authorization': `Bearer ${stripeEnvironment.authKey}` });
  readonly user = StorageService.get(StorageValue.User);
  readonly headers = new HttpHeaders({
    ACCESS_CONTROL_ALLOW_ORIGIN: '*',
    ACCESS_CONTROL_ALLOW_HEADERS: 'Origin, X-Requested-With, Content-Type, Accept'

  });

  constructor(
    private http: HttpClient,
    private proxyService: ProxyService
  ) {
    loadStripe(stripeEnvironment.publishKey).then(stripe => this._stripe = stripe);
  }

  getStripeElements(): StripeElements {
    return this._stripe.elements({});
  }

  acceptPayment(dto: AcceptPaymentDto) {
    console.log('dto', dto)
    return this.http.post<any>(`${environment.url}stripe/accept_payment`, dto, {
      headers: this.headers
    })
  }

  createCheckoutSession(dto: CheckoutSessionInfoDto) {
    return this.proxyService.post(`stripe/create-checkout-session`, dto, null)
  }

  // TODO Все методы оплаты в этом сервисе, но его назвать бы иначе (не stripe)
  createOrder(dto: CheckoutSessionInfoDto) {
    return this.proxyService.post(`order/create-successful-order`, dto, null)
  }

  // TODO Избавиться от any для orderResult
  sendSessionURLToParent(orderId: number) {
    return this.proxyService.post(`stripe/send-session-url-to-parent/${orderId}`, {}, null)
  }

  ordersCheckVerificationCode(code: string) {
    return this.proxyService.post(Urls.StripeOrdersCheckVerificationCode, {VerificationCode: code}, null)
  }

  async getSessionUrlById(orderId) {
    return await this.proxyService.get(`stripe/get-session-url-by-id`, {id: orderId}, null).toPromise();
  }

  async createSuccessfulOrderById(orderId) {
    return await this.proxyService.post(`order/create-successful-order-by-id?id=${orderId}`, {}, null).toPromise();
  }
}
