export enum PaymentSystemTypesEnum {
  Stripe = 0,
  Physical = 1,
  SberBank = 2
}

export type PaymentSystemType = {id: number, name: string};

export const PaymentSystemTypes: PaymentSystemType[] = [
  {id: PaymentSystemTypesEnum.Stripe, name: 'Stripe'},
  {id: PaymentSystemTypesEnum.SberBank, name: 'playgrounds.payment_type_yookassa'},
]
