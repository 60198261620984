<!--table starts-->
<section id="ng-tables">
  <div class="row text-left">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">

            <!--<button routerLink="/services/new" type="button" class="btn btn-primary mr-2 m-0"><i class="ft-check-square mr-1"></i>Добавить услугу</button>-->

            <form>
              <div class="table-responsive">
                <app-qr-modal #qrModal ></app-qr-modal>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th *ngIf="data.actions != null" scope="col">{{data.actions.displayName | translate}}</th>
                      <th *ngFor="let header of data.headers" scope="col">{{header.displayName | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <td *ngIf="total==0" class="empty-table-info" [attr.colspan]="data.headers.length + 1">{{emptyTableTitle ? (emptyTableTitle | translate) : ('common.the_result_not_found' | translate)}}</td>
                    <!-- <tr [ngClass]="{'active': row == selectedRow, 'deleted': row?.isDeleted}" *ngFor="let row of rows" (click)="rowSelected(row)"> -->
                    <tr [ngClass]="{'active': row == selectedRow, 'online': row?.isHighlight, 'is-not-read': row?.isRead === false }" *ngFor="let row of rows" (click)="rowSelected(row)">
                      <ng-container *ngIf="!row?.isDeleted" >
                        <td class="actions-td">
                          <a [matMenuTriggerFor]="tooltipMenu" *ngIf="data.actions && data.actions.actions.length > 0" class="success p-0 mr-2"
                             title="{{'common.more_details' | translate}}">
                            <img class="send-icon" src="assets/img/icons/three-dots-vertical.svg" alt="actions">
                          </a>
                        </td>

                        <td  *ngFor="let header of data.headers; let i = index"
                             [attr.data-label]="header.displayName | translate" class="table-td">
                          <ng-container [ngSwitch]="data.headers[i]?.type">
                            <img *ngSwitchCase="'image'" [src]="mediaUrl + row[data.headers[i].property]" onerror="this.src='assets/img/no-image.png';" alt="">
                            <div class="btnPrint" *ngSwitchCase="'qr'">
                              <button class="btnPrint" (click)="onPrintQRModal(row[data.headers[i].property])">
                                    <qrcode
                                      [qrdata]="row[data.headers[i].property]"
                                      [width]="75"
                                      [elementType]="'img'"
                                      [errorCorrectionLevel]="'M'">
                                    </qrcode>
<!--                                <img [src]='getQR(row[data.headers[i].property])' alt="">-->
                              </button>
                            </div>
                            <ng-container *ngSwitchCase="null">
                              {{row | gridrowvalue : data.headers[i]?.property | translate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                              {{dateUtility.convertDate(row[data.headers[i].property], data.headers[i].property)}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'boolean'">
                              {{(row | gridrowvalue : data.headers[i]?.property) == true
                              ? (data.headers[i]?.trueLabel | translate)
                              : (data.headers[i]?.falseLabel | translate)}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'fiscalRegisterStatus'">
                              {{ getFiscalStatusName((row | gridrowvalue : data.headers[i]?.property)) | translate }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'list'">
                              {{getPropertyList(row[data.headers[i].property])}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'link'">
                              <a href="{{row | gridrowvalue : data.headers[i]?.property}}">
                                {{row | gridrowvalue : data.headers[i]?.property}}
                              </a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'percente'">
                              {{row | gridrowvalue : data.headers[i]?.property}}%
                            </ng-container>
                            <ng-container *ngSwitchCase="'html'">
                              {{row | gridrowvalue : data.headers[i]?.property | removeHtmlTags}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'file'">
                              <span (click)="getFiles()">
                                <img class="send-icon" src="assets/img/icons/clip.svg" alt="send-message">
                                <span class="mr-1">{{row[data.headers[i].property]}}</span>
                                <span>{{'common.files' | translate}}</span>
                              </span>
                            </ng-container>
                          </ng-container>

                        </td>
                          <!-- TODO: Работу с действиями нужно унифицировать (параметризировать выбор иконки, выбор обработчика, текст, и передавать массив действий) -->
                        <mat-menu
                          #tooltipMenu='matMenu'
                          class="actions-tooltip"
                        >
                          <a *ngIf="getAction(actionType.Chat) != null" class="success p-0 mr-2"
                             (click)="setSourceAndNavigateToChat(actionType.Chat, row)">
                            {{'common.chat' | translate}}
                            <img class="send-icon" src="assets/img/icons/send-message.svg" alt="send-message">
                          </a>
                          <div>
                            <div class="table-action"
                                 *ngIf="getAction(actionType.Details) != null"
                                 (click)="details(actionType.Details, row)">
                              <a class="details">
                                {{getActionText(actionType.Details) | translate}}
                              </a>
                            </div>
                            <div>
                              <div class="table-action"
                                   *ngIf="getAction(actionType.RepeatFiscal) != null && row?.fiscalStatus != FiscalRegistrarEnum.FiscalComplete && row?.isSuccessful"
                                   (click)="repeatFiscal(row.id)">
                                <a class="details">
                                  {{getActionText(actionType.RepeatFiscal) | translate}}
                                </a>
                              </div>
                              <div class="table-action"
                                   (click)="findReceipt(row.id)"
                                   *ngIf="getAction(actionType.FindReceipt) != null && row?.fiscalStatus != FiscalRegistrarEnum.FiscalComplete && row?.isSuccessful">
                                <a class="details">
                                  {{getActionText(actionType.FindReceipt) | translate}}
                                </a>
                              </div>
                              <div class="table-action"
                                   *ngIf="row?.fiscalStatus === FiscalRegistrarEnum.FiscalComplete"
                                   (click)="makeReceiptCopy(row.id)">
                                <a class="unpaid-order details mt-2">
                                  {{'actions.make_a_receipt_copy' | translate}}
                                </a>
                              </div>
                              <div class="table-action"
                                   *ngIf="row.isDefault === false"
                                   (click)="setDefault(row)">
                                <a class="unpaid-order details mt-2">
                                  {{'actions.select_the_default' | translate}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column"
                               *ngIf="getAction(actionType.UnpaidOrder) != null && row.isSuccessful === false">
                            <!--  Временно скрыто d-none-->
                            <div class="table-action d-none">
                              <a class="unpaid-order details mt-2"
                                 (click)="createCheckoutPayment(row.id)">
                                {{'actions.stripe_proceed_to_payment' | translate}}
                              </a>
                            </div>
                            <div class="table-action"
                                 (click)="createCheckoutPaymentAndSendURLToParent(row.id)">
                              <a class="unpaid-order details mt-2">
                                {{'actions.send_payment_link_via_sms' | translate}}
                              </a>
                            </div>
                            <div class="table-action"
                                 (click)="openWithoutSystemPayment(row.id)">
                              <a class="unpaid-order details mt-2">
                                {{'actions.cash_register_go_to_payment' | translate}}
                              </a>
                            </div>
                          </div>
                          <a *ngIf="getAction(actionType.Edit) != null" class="success p-0 mr-2"
                             (click)="setSourceAndNavigate(actionType.Edit, row)"
                             title="{{'actions.edit' | translate}}">
                            {{'actions.edit' | translate}}
                            <app-edit-svg></app-edit-svg>
                          </a>
                          <a *ngIf="getAction(actionType.Delete) != null && !row.isDeleted" class="danger p-0"
                             (click)="delete(row)"
                             title="{{'actions.delete' | translate}}">
                            {{'actions.delete' | translate}}
                            <app-delete-svg></app-delete-svg>
                          </a>
                          <a *ngIf="getAction(actionType.Duplicate) != null" class="danger p-0"
                             (click)="duplicate(row)">
                            <i class="ft-x font-medium-3"></i>
                          </a>
                          <a *ngIf="getAction(actionType.Close) != null" class="danger p-0"
                             (click)="setSourceAndNavigate(actionType.Close, row)"
                             title="{{'actions.close' | translate}}">
                            {{'actions.close' | translate}}
                            <i class="danger ft-x font-medium-3"></i>
                          </a>
                        </mat-menu>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="pagination" class="d-flex p-2">

                <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="currentPage" [maxSize]="4" [rotate]="true"
                  [ellipses]="true" [boundaryLinks]="true" [pageSize]="pageSize">
                </ngb-pagination>
                <div class="go-to-page d-flex ml-2">
                  <!-- <input type="number" class="form-control" placeholder="#" [(ngModel)]="pageInputValue" [ngModelOptions]="{standalone: true}"> -->
                  <!-- <button type="button" class="move btn btn-primary" (click)="goToPage()">Перейти</button> -->
                </div>
              </div>

            </form>
            <ngx-spinner [name]="'table-spinner'"></ngx-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--table ends-->
