import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import { ActionInst, ActionType, GridModel } from '../../models/grid.model';
import { ProxyService } from 'app/shared/services/proxy.service';
import { PagedResponse } from 'app/shared/models/paged-response.model';
import { ToastService } from '../../services/toast.service';
import { DateUtility } from '../../utils/date.utility';
import { environment } from 'environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from 'app/shared/models/base.model';
import {from, Subject} from 'rxjs';
import { DataTransferService } from '../../services/data-transfer.service';
import { DomSanitizer } from '@angular/platform-browser';
import { QrModalComponent } from '../qr-modal/qr-modal.component';
import { StripeService } from '../../services/stripe.service';
import {FiscalRegisterService} from "../../services/fiscal-register.service";
import {getErrorText, hasError} from "../../utils/errors.utility";
import { NgxSpinnerService } from "ngx-spinner";
import { FiscalRegistrarEnum, FiscalRegistrarStatuses} from "../../enums/fiscal-registrar-status.enum";
import { PaymentManagerService } from "../../services/payment-manager.service";

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  mediaUrl = environment.imageUrl;
  dateUtility = new DateUtility();
  actionType = ActionType;
  skip = 1;

  selectedRow = null;
  rowChanged: Subject<any> = new Subject<any>();

  currentPage = 1;
  pageSize = 10;
  rows: BaseModel[];
  total: number;
  pageInputValue: any;
  highlightedRows: Array<any>;
  actionDetailText: string
  public isVisibleModal = false;
  public FiscalRegistrarEnum = FiscalRegistrarEnum;

  @Input() callbackFunction: (args: any) => any;
  @Input() data: GridModel<any>;
  @Input() rowKey: string;
  @Input() defaultData: any[];
  @Input() defaultCount: number;
  @Input() selectable = true;
  @Input() pagination = true
  @Input() emptyTableTitle: string
  @Output() setDefaultData = new EventEmitter();
  @Output() openModal = new EventEmitter<string>();
  @Output() openFiscalModal = new EventEmitter<number>();
  @Output() rowsChange = new EventEmitter<any[]>();
  @ViewChild('qrModal') qrModal: QrModalComponent;
  private defaultDto = null;

  constructor(
    private proxy: ProxyService,
    private ref: ChangeDetectorRef,
    private toast: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataTransferService: DataTransferService,
    private sanitizer: DomSanitizer,
    private readonly _stripe: StripeService,
    private paymentManagerService: PaymentManagerService,
    private fiscalRegisterService: FiscalRegisterService,
    private spinner: NgxSpinnerService
  ) {}

  getFiscalStatusName(key: number): string {
    if (key == null) return;
    return FiscalRegistrarStatuses.find(item => item.key === key).name;
  }

  async getGridResult() {
    if (this.defaultData) {
      this.rows = this.defaultData;
      this.total = this.defaultCount ? this.defaultCount : this.defaultData.length;
      this.ref.detectChanges();
      this.highlightOnline();
      return;
    }
    if (this.pagination && !this.data?.filter) {
      this.data.filter = {
        take: this.pageSize,
        skip: 1
      }
    }
    const res = await this.proxy.get<PagedResponse<any>>(this.data.sourceUrl, this.data.filter, null).toPromise();
    if (!res.hasError) {
      if (this.callbackFunction && typeof this.callbackFunction === 'function') {
        const data = this.callbackFunction(res.data.entities);
        console.log(data)
      }
      this.rows = res.data.entities;
      this.rowsChange.emit(this.rows);
      this.total = res.data.count;
      this.ref.detectChanges();
      this.highlightOnline();
    }
  }

  highlightOnline() {
    this.highlightedRows = this.rows.filter((row) =>
      row?.lastLoginData !== undefined && new Date(row?.lastLoginData).getDate() === new Date().getDate())
    if (this.rows && this.highlightedRows) {
      this.rows.forEach((row) => {
        this.highlightedRows.forEach((highlight) => {
          if (row.id === highlight.id) {
            row.isHighlight = true;
          }
        })
      })
    }
  }

  rowSelected(row) {
    if (!this.selectable) {
      return
    }
    this.selectedRow = row;
    this.rowChanged.next(this.selectedRow);
  }

  loadPage(data) {
    this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {skip: data}});
    this.data.filter.skip = data;
    this.setDefaultData.emit(this.data.filter);
  }

  async details(type: ActionType, row) {
    const url = this.getAction(type).url;
    this.dataTransferService.data = row
    await this.router.navigate([url + `/${row.id}`]);
  }

  getActionText(type: ActionType): string {
    return this.getAction(type).text
  }

  async delete(row) {
    const func = this.getAction(ActionType.Delete).func;

    if (func) {
      func(row);
      return;
    }

    this.toast.delete(await this.proxy.post(this.data.actions.actions.find(a =>
      a.action === ActionType.Delete).url, {id: row.id}, null), () => {
      this.getGridResult();
    });
  }

  duplicate(row) {

  }

  public getFiles() {
    // console.log('get files');
  }


  getAction(type: ActionType): ActionInst {
    return this.data.actions.actions.find(x => x.action === type);
  }

  getPropertyList(list) {
    let result_string = ''
    list.forEach(product => {
      result_string += product['name'] + ', '
    });
    return result_string;
  }

  getQR(img) {
    const objectURL = 'data:image/png;base64,' + img;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onPrintQRModal(img) {
    this.qrModal.open(img);
  }

  async createCheckoutPayment(orderId: number) {
    const data = await this._stripe.getSessionUrlById(orderId)
    if (data.hasError) {
      this.toast.error(data.errors.map(e => e.description).join(', '));
      return
    }
    if (typeof data.data === 'string') {
      window.location.href = data.data;
    } else {
      this.toast.error('Произошла ошибка')
    }
  }

  createCheckoutPaymentAndSendURLToParent(orderId: number) {
    this.paymentManagerService.currentService.sendSessionURLToParent(orderId)
      .subscribe(sendSessionURLResult => {
      if (sendSessionURLResult.hasError) {
        this.toast.error(sendSessionURLResult.errors.map(e => e.description).join(', '));
      } else {
        this.toast.success('toast_notices.payment_link_has_been_sent');
      }
    })
  }

  openWithoutSystemPayment(orderId) {
    this.openModal.emit(orderId);
  }

  async setSourceAndNavigate(type: ActionType, row: any, rowKey: string = 'id') {
    const editFunc = this.getAction(ActionType.Edit).func;
    if (editFunc) {
      editFunc(row);
      return;
    }

    const url = this.getAction(type).url;
    this.dataTransferService.data = row

    await this.router.navigate([url + `/${row[rowKey]}`]);
  }
  async setSourceAndNavigateToChat(type: ActionType, row: any) {
    const url = this.getAction(type).url;
    this.dataTransferService.data = row
    await this.router.navigate([url + `/${row.id}`]);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      // TODO переименовать во всем проекте переменную skip, например, pageNumber
      this.skip = Number(params.skip) || 1;
      this.data.filter = {
        ...this.data.filter,
        skip: this.skip
      }
      await this.getGridResult();
      this.currentPage = this.skip;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  repeatFiscal(orderId: number) {
    console.log(orderId)
    this.fiscalRegisterService.repeatFiscal(orderId)
      .subscribe(res => {
        if (hasError(res)) {
          this.toast.error(getErrorText(res));
        } else {
          this.toast.success('toast_notices.repeated_fiscalization_performed');
        }
      })
  }

  findReceipt(id: number) {
    this.openFiscalModal.emit(id);
  }

  makeReceiptCopy(id: number) {
    this.fiscalRegisterService.makeReceiptCopy(id)
      .subscribe(res => {
        if (res.hasError) {
          this.toast.error(getErrorText(res));
        } else {
          this.toast.success('toast_notices.copy_check_will_be_printed')
        }
      })
  }

  setDefault(selectedRow: any) {
    console.log(selectedRow)
    this.defaultData = this.defaultData.map(row => {
      if (row.id === selectedRow.id) {
        return { ...row, isDefault: true };
      } else {
        return { ...row, isDefault: false };
      }
    });
    this.defaultDto = {
      playgroundId: selectedRow.playgroundId,
      fiscalRegisterId: selectedRow.id
    }
    this.fiscalRegisterService.setDefaultFiscalRegister(this.defaultDto)
      .pipe()
      .subscribe( async res => {
        if (res.hasError) {
          this.toast.error('toast_notices.default_fiscal_registrar_could_not_be_selected');
        } else {
          await this.getGridResult();
          this.toast.success('toast_notices.fiscal_register_selected_by_default');
          this.ref.detectChanges();
        }
      })
  }
}

