import { Injectable } from '@angular/core';
import { ProxyService, Urls } from './proxy.service';

@Injectable({
  providedIn: 'root'
})
export class PlaygroundService {

  constructor(private proxyService: ProxyService) {
  }

  async addPlayground(request) {
    const data = await this.proxyService.post(Urls.AddPlayground, request, null).toPromise();
    return data;
  }
  async updatePlayground(request) {
    const data = await this.proxyService.post(Urls.UpdatePlayground, request, null).toPromise();
    return data;
  }

  async closePlayground(request) {
    const data = await this.proxyService.post(Urls.ClosePlayground, request, null).toPromise();
    return data;
  }

  getPlaygroundById(id: number) {
    return this.proxyService.get<any>(Urls.GetPlaygroundById, {id: id}, null);
  }

}
