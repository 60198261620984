import { PaymentSystemType } from '../enums/payment-system-type.enum';

export enum ProductType {
  Hourly = 1,
  Unlimited = 2,
  Additional = 3,
  Photo = 4
}

export interface DraftItem {
  productId: string;
  priceId: string;
  quantity: number;
}

export interface SeanceInfoDto {
  playgroundId: number;
  childId: number;
  durationInSeconds: number;
  isUnlimitedSession: boolean;
  childSeanceId?: number;
}

export interface AcceptPaymentDto {
  productType: ProductType;
  paymentMethodId: string;
  draft: DraftItem[];
  seancesInfo: SeanceInfoDto[];
  parentId: number;
}

export interface CheckoutSessionInfoDto {
  productItems: ProductItem[];
  childIds: number[];
  parentId: number;
  playgroundId: number;
  successUrl: string;
  cancelUrl: string;
  mode: string;
  fiscalRegisterId?: number;
  paymentSystemType?: PaymentSystemType;
}

export interface ProductItem {
  productId: string;
  quantity: number;
  maxCountChildren?: number
}

export interface Product {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  priceId: string;
  priceAmount: number;
  quantity?: number;
  maxCountChildren?: number
}

export interface Card {
  id: string;
  brand: string;
  last4: string;
}

export interface DraftItem {
  productId: string;
  priceId: string;
  quantity: number;
}
