import { Injectable } from "@angular/core";
import { ProxyService } from "./proxy.service";
import { IntegrationPaymentType } from "../enums/integration-payment-type.enum";
import { PaymentServiceModel } from "../models/payment-service.model";
import { CheckoutSessionInfoDto, ProductType  } from '../models/stripe.model';
import { Observable } from "rxjs";
import { ResponseModel } from "../models/response.model";
import {OrderDraftItem, OrderSeanceInfo, SberInvoiceDto} from '../models/sber.model';

@Injectable({
  providedIn: 'root'
})

export class SberService implements PaymentServiceModel {
  readonly availableStrategy: string = IntegrationPaymentType.ExternalStrategy.paymentByPaymentLink;

  constructor(private proxyService: ProxyService) {}

  // Если это возможно, перенести всю логику создания SberOrderInvoice на бэк
  private craeteSberInvoiceDto(sessionInfoDto: CheckoutSessionInfoDto) {
    let drafts: OrderDraftItem[];
    let orderSeanceInfo: OrderSeanceInfo[];

    drafts = sessionInfoDto.productItems.map(item => {
      return ({
        ProductId: parseInt(item.productId, 10),
        Quantity: item.quantity
      })
    });

    orderSeanceInfo = sessionInfoDto.childIds.map(childId => {
      return ({
        PlaygroundId: sessionInfoDto.playgroundId,
        ChildId: childId,
        DurationInSeconds: 0, // для безлимита на бэке проставится значение при создании сеанса (независимо от значения)
        IsUnlimitedSession: true,
      });
    });

    const requestBody: SberInvoiceDto = {
      productType: ProductType.Unlimited, // необязательное поле, но требует dto на бэке
      draft: drafts,
      seancesInfo: orderSeanceInfo,
      successUrl: sessionInfoDto.successUrl,
    }

    return requestBody;
  }

  createCheckoutSession(sessionInfoDto: CheckoutSessionInfoDto): Observable<ResponseModel<any>> {
    const sberInvoiceDto = this.craeteSberInvoiceDto(sessionInfoDto);
    const requestBody = {
      sberInvoiceDto: sberInvoiceDto,
      parentId: sessionInfoDto.parentId
    }

    return this.proxyService.post('sber/create-checkout-session', requestBody, null);
  }

  sendSessionURLToParent(orderId: string): Observable<ResponseModel<any>> {
    return this.proxyService.post(`sber/send-session-url-to-parent/${orderId}`, {}, null)
  }
}
